@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');

.follow-priority{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 100px;
  font-weight: 500;

}
.follow-nextdate{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  width: 110px; 
  font-weight: 500;

}
.follow-status{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF !important;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 80px; 
  font-weight: 500;
  

}
.follow-assignto{
    background: linear-gradient(to right, #0B5394, #00619A);
    color: #FFFFFF;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-align: center;
    width: 100px;
  }
.follow-action{
  background: linear-gradient(to right, #0B5394, #00619A) ;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 90px; 
  font-weight: 500;

}
.follow-company{
  background: linear-gradient(to right, #0B5394, #00619A) ;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 100px; 
  font-weight: 500;

}
.header-quotation{
  background: linear-gradient(to right, #0B5394, #00619A) ;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 90px; 
  font-weight: 500;

}
.date-feild {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;
    
  }
  
  
  .date-feild .form-control {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    width: 250px;
    margin-left: 10px;
    margin-top: 20px;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  
  .date-feild .btn {
    padding: 9px 16px;
    font-size: 14px;
    background-color: #00619A;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 21px;
    margin-left: -3px !important;
    

  }
  .date-feild .btn:hover {
    background-color: #0B5394;
  }
  .follow-box{
    /* border: 1px solid #ccc; */
  width: 100%;
  height: auto;
  padding: 8px;
  border-radius: 5px;
  margin: 2px;
  margin-top: 10px;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); */
  }
  .spinner-container {
    display: flex;
    justify-content: center;   /* Centers horizontally */
    align-items: center;       /* Centers vertically */
    position: fixed;           /* Fixed position to stay in place */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);  /* Optional dimmed background */
    z-index: 9999;             /* Ensure it appears above other elements */
    flex-direction: column;    /* Stack text and spinner vertically */
    gap: 10px;                 /* Space between text and spinner */
  }
  
  .spinner-custom {
    width: 2rem;
    height: 2rem;
    border-width: 0.3rem; 
    border-color:#243a73 transparent transparent transparent;
    animation: spinner-border 1s linear infinite;
  }
  
  @keyframes spinner-border {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-text {
    font-size: 1rem;
    font-weight: bold;
    color: #243a73;
    text-align: center;
    padding: 10px;  /* Optional padding */
  }
 .follfilter-titles {
    margin-top: 20px;
    text-align: left;
    color: #ffffff !important;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  
  .follfilter-close {

    top: -3px;
    left: 400px;
    cursor: pointer;
    font-size: 28px;
 
  }
  .modal-head {
    position: relative; 
    color: #243a73;/* Ensure the close button is positioned correctly */
  }
  