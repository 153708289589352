
  .follow-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .follow-modal-container {
    background: #A5BECC;
    padding: 30px;
    border-radius: 5px;
    width: 70%;
    max-width: 450px;
    position: relative;
  }
  
  .follow-modal-header {
    background-color: #243A73;
    color: #FFFFFF;
    font-size: 12px;
    height: 40px;
    margin-top: -31px;
    margin-bottom: 30px;
    position: relative;
    width: 450px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -30px;
  }
  
  .follow-modal-title {
    margin-top: 10px;
    text-align: left;
    color: #ffffff;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;
  }
  
  .follow-close-modal {
    position: absolute;
    top: -5px;
    left: 420px;
    cursor: pointer;
    font-size: 28px;
    color: white;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .follow-form-label {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    margin-right: 10px; 
    width: 170px;        
  }
  .follow-input-field {
    width: 100%;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 34px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #b8b8b8;
  }
  .follow-input{
    width: 100%;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 28px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    margin-left: -50px;
  }
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .checkbox-input{
    margin-left: -50px;
  }