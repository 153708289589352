@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Nunito&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap");
.body{
  overflow-x: hidden;
  overflow-y: hidden;
}
.basic-info-title {
  background-color: #243a73;
  color: white;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  margin-left: 10px;
  width: 100%;
  margin-top: 20px;
  /* Ensures padding is included in the width */
}
.company-info-title {
  background-color: #243a73;
  color: white;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  margin-left: 10px;
  width: 100%;
  margin-top: 20px;
  height: 25px;
  line-height: 5px; /* Vertically centers the text */
}

.combasic-info-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 10px;
  background-color: #a5becc;
  padding: 25px 10px; /* Increase top and bottom padding */
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
  width: 100%;
}
.basic-info-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 10px;
  background-color: #a5becc;
  padding:  10px; /* Increase top and bottom padding */
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
  width: 100%;
}
.basic-info-row {
  display: flex;
  align-items: center;
}
.basic-info-label {
  width: 200px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000;
  margin-bottom: 0;
}
.basic-info-search-icon {
  position: absolute;
  left: 370px;
  top: 14%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #888;
}
.required {
  color: red;
}
/* Input styles */
/* Input styles */
.basic-info-input {
  width: calc(100% - 20px);
  height: 35px;
  border: 1px solid #d9d9d9; /* Default border color */
  border-radius: 2px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #6d6d6d;
  padding: 0 10px;
  transition: border-color 0.3s ease-in-out; /* Transition for smooth border change */
}
.qbasic-info-input {
  width: calc(100% - 20px);
  height: 35px;
  border: 1px solid #d9d9d9; /* Default border color */
  border-radius: 2px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #6d6d6d;
  padding: 0 10px;
  width:165px;
  transition: border-color 0.3s ease-in-out; /* Transition for smooth border change */
}
/* Dropdown list styles */
.basic-info-dropdown-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 50px;
  left: 90px;
  right: 0;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  width: 310px;
}
.probasic-info-dropdown-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 105px;
  left: 133px;
  right: 0;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  width: 238px;
}
.basic-info-dropdown-item {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
}
.basic-info-dropdown-item:hover {
  background-color: #e6f7ff;
}
.row-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 30%;
  gap: 10px;
}
.product-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #a5becc;
  padding: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
  width: 100%;
}
.company-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #a5becc;
  padding: 20px 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
  width: 100%;
  margin-top: -16px;
}
.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.product-table {
  width: 100%;
  margin-top: 2px;
  border-collapse: collapse;
  background-color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  color: #333;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.product-table th {
  background-color: #0b5394;
  color: #fff;
  padding: 8px;
  width: 200px;
  text-align: left;
  text-transform: capitalize;
  letter-spacing: 0.05em;
}
.product-table td {
  padding: 8px;
  width: 200px;
  border-bottom: 1px solid #ddd;
}
/* Alternate row color */
.product-table tr:nth-child(even) {
  background-color: #f9fbfc;
}
/* Hover effect for rows */
.product-table tr:hover {
  background-color: #e1effa;
  transition: background-color 0.3s ease-in-out;
}
/* Remove last row border */
.product-table tr:last-child td {
  border-bottom: none;
}
.edit-icon {
  margin-left: 10px;
  cursor: pointer;
  color: rgb(103, 151, 255);
  background-color: rgba(103, 151, 255, 0.1);
  border-radius: 5px;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all 0.3s ease;
}
.edit-icon:hover {
  background-color: rgba(103, 151, 255, 0.2);
  transform: scale(1.1);
}
.trash-icon {
  margin-right: 10px;
  cursor: pointer;
  color: rgb(255, 103, 103);
  background-color: rgba(255, 103, 103, 0.1);
  border-radius: 5px;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all 0.3s ease;
}
.trash-icon :hover {
  background-color: rgba(255, 103, 103, 0.2);
  transform: scale(1.1);
}
.total-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.total-label {
  width: 150px;
  text-align: left;
  font-family: "Poppins";
  font-size: 12px;
  color: #000;
  margin-top: -7px;
}
.total-input {
  width: 100%;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: "Manrope";
  font-size: 14px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  margin-top: -4px;
  margin-left: -30px;
}
.add-product-button {
  padding: 7px 15px;
  font-size: 14px;
  background-color: #0b5394;
  font-family: "Poppins", sans-serif;
  border: none;
  font-weight: 500;
  border-radius: 3px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
}
.button-wrapper {
  display: flex;
  justify-content: space-between; /* Distribute space between the buttons */
  align-items: center; /* Vertically align buttons */
  width: 100%; /* Ensure buttons take full width */
}
/* Styling for the delete button */
.delete-contact-button {
  padding: 7px 15px;
  font-size: clamp(12px, 1.5vw, 14px); 
  background-color: #0b5394;
  font-family: "Poppins", sans-serif;
  border: none;
  font-weight: 500;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
  flex-shrink: 0; 
  margin-left: 420px; /* Prevent button from shrinking */
}
.delete-contact-button:hover {
  background-color: #084073; 
}
.header-container {
  display: flex;
 
  justify-content: space-between;
  align-items: center; /* Ensures the icons are vertically aligned with the heading */
 margin-bottom: 16px; 
} 
.icon-buttons {
  display: flex;
  gap: 8px; 
  margin-top: 20px;
  background-color: #243a73;
  margin-left: -80px;
  color: white;
  width:60px;
 
}
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem; /* Adjust icon size */
  color: white; /* Adjust icon color */
}
.icon-button:hover {
  color: #000; /* Change color on hover */
}
.icon {
  font-size: 1.5rem;
  font-weight: bold;
}
.right-align-button {
  display: flex;
  justify-content: flex-end; 
  align-items: flex-end;
  width: 100%;
}
.add-company-button {
  padding: 7px 15px;
  font-size: clamp(12px, 1.5vw, 14px); 
  background-color: #0b5394;
  font-family: "Poppins", sans-serif;
  border: none;
  font-weight: 500;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
    margin-right: -393px;
}
.add-company-button:hover {
  background-color: #084073; 
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-container {
  background: #a5becc;
  padding: 30px;
  border-radius: 5px;
  width: 70%;
  max-width: 400px;
  position: relative;
}
.modal-header {
  background-color: #243a73;
  color: #ffffff;
  font-size: 12px;
  height: 40px;
  margin-top: -31px;
  margin-bottom: 30px;
  text-align: right;
  position: relative;
  right: 9%;
  width: 118%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: right;
  justify-content: center;
}
.modal-titles {
  margin-top: 10px;
  text-align: left;
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
}
.close-modal {
  position: absolute;
  top: -5px;
  left: 370px;
  cursor: pointer;
  font-size: 28px;
  color: white;
}
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-label {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  margin-right: 10px; 
  width: 140px;
}
.proinput-field{width: 165%;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 34px;
  font-size: 14px;
  margin-left: -45px;
  font-family: "Poppins", sans-serif;
  color: black;}
  .search-icon {
  position: absolute;
  right: 40px; /* Position the icon on the right side of the input */
  top: 20%; /* Vertically center the icon */
  transform: translateY(-17%); /* Adjust for perfect centering */
  color:grey; /* Adjust color as needed */
}
.input-field {
  width: 100%;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 34px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: black;
}
.product-input {
  width: calc(100% - 20px); /* Adjust width to fit */
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 34px;
  font-size: 14px;
  margin-left: 10px; /* Align with the label */
  cursor: pointer;
}
.textarea-field {
  width: 100%;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 60px;
  font-size: 14px;
}
.modal-footer {
  margin-top: 10px;
  text-align: right;
}
.submit-button {
  padding: 10px 20px;
  background-color: #243a73;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.dropdown-icon {
  position: absolute;
  right: 40px; /* Keeps the icon aligned to the right */
   font-size: 12px;
  cursor: pointer;
  color: #888;
}
.dropdown-list-1 {
  position: absolute;
  top: 100px; /* Adjust this based on your input's height */
  left: 134px;
  right: 0;
  max-height: 180px;
  overflow-y: auto;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  width: 237px;
}
.dropdown-item {
  padding: 8px 12px;
 
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
}
.enquiry-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  background-color: #a5becc;
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
  width: 100%;
}
.enquiry-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 30%;
  gap: 10px;
}
.enquiry-label {
  width: 150px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000;
}
.enquiry-input {
  width: calc(100% - 20px);
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #6d6d6d;
  padding: 0 10px;
}
.dropdown-list2 {
  position: absolute;
  top: 20px;
  width: 280px;
  max-height: 150px;
  left: 520px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1000;
  border-radius: 2px;
}
.dropdown-item2 {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}
.dropdown-item:hover {
  background-color: #f5f5f5;
}
.datepicker-container {
  display: flex;
  flex: 1;
}
.custom-datepicker {
  width: 120%;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #6d6d6d;
  padding: 0 10px;
}
.enquiry-primary {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  background: linear-gradient(to right, #0b5394, #00619a);
  border: 1px solid #00619a;
  border-radius: 3px;
  padding: 10px 10px;
  width: auto;
  margin-top: 0px;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
  margin-right: 100px;
}
.enquiry-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  flex-wrap: wrap;
}
.enquiry-dialog-paper {
  height: 200px;
  max-width: 600px;
  width: 50%; /* Responsive width */
  position: fixed; /* Ensures the dialog stays fixed in the viewport */
  top: 20%; /* Center vertically */
  left: 25%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjusts to center the dialog box */
  border: 1px solid #e0e0e0; /* Subtle border */
  border-radius: 15px; /* Rounded corners */
  background: linear-gradient(to bottom, #ffffff, #f8f8ff); /* Light gradient */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for aesthetics */
  overflow: hidden!important; /* Prevent scrolling inside the dialog */
  z-index: 1000; /* Ensure the dialog is above other elements */
}
.enquiry-dialog-content {
  font-family: "Poppins", sans-serif; /* Font family */
  font-weight: 600; /* Bold font weight */
  font-size: 16px; /* Font size */
  display: flex; /* Flexbox for alignment */
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center horizontally */
  padding: 24px; /* Internal padding */
  justify-content: center; /* Center vertically */
  text-align: center; /* Center text */
  color: #333333; /* Text color */
  overflow: hidden !important;
}
.enquiry-success-icon {
  width:80px;
  height: 80px;
  margin-bottom: 16px;
}
.follow-success-message {
  word-break: break-word;
}
.success-message {
  font-size: 18px;
  font-weight: 500;
  color: #4caf50;
  text-align: center;
}
.enquiry-dialog-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 15px;
  padding: 16px,
}
.enquiry-ok-button {
  width: 80px;
  background: linear-gradient(90deg, #4caf50, #81c784);
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  border: none;
  border-radius: 2px;
  text-transform: capitalize;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
}
.enquiry-ok-button:hover {
  background: linear-gradient(90deg, #81c784, #4caf50);
  color: white;
}
.reset-but {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.enquiry-area {
  width: calc(100% - 20px);
  height: 140px;
  padding: 12px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}
.button-icon {
  margin-right: 8px;
  font-size: 18px;
}
.save-icon {
  margin-right: 6px;
  font-size: 25px;
}
.reset-icon {
  margin-right: 6px;
  font-size: 25px;
  color: #00619a;
}
.enquiry-reset-btn {
  font-family: "Manrope", sans-serif;
  color: #00619a;
  background: #fff;
  border: 1px solid #00619a;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 3px;
  padding: 10px 9px;
  width: auto;
  margin-top: -70px;
  margin-bottom: 20px;
  margin-left: 140px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
}
.enquiry-reset-btn:hover {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #fff;
  border-color: #00619a;
}
.enquiry-reset-btn:hover .reset-icon {
  color: #fff;
}
.button-container {
  position: relative;
 height:50px;
 top: -75px;
 margin-bottom:-55px;
 margin-right: -36px;
 
  
}
.button-backArrow {
  position: absolute; /* Position the button relative to the container */
  top: 20px; /* Adjust the top position */
  right: 30px; /* Move the button to the right */
  padding: 10px;
 
  background-color: #0b5394;  
 
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 120px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  
}
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px; /* Circle width */
  height: 20px; /* Circle height */
  background-color: white;/* Circle background color */
  border-radius: 50%; /* Make the div round */
  margin-right: 8px;
  margin-left: 10px; /* Space between the circle and the text */
}
.back-text {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  font-family: "Manrope", sans-serif;
  margin-left: 50px; /* Add space between the icon and text */
  color:white; 
  margin-top: -23px;/* Customize color as needed */
}
.company-dialog-paper {
  height: 200px;
  max-width: 600px;
  width: 50%; /* Responsive width */
  position: fixed; /* Ensures the dialog stays fixed in the viewport */
  top: 50%; /* Center vertically */
  left: 20%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjusts to center */
  border: 1px solid #e0e0e0; /* Subtle border */
  border-radius: 15px; /* Rounded corners */
  border-radius: 5px; /* Rounded corners */
  background: linear-gradient(to bottom, #ffffff, #f8f8ff); /* Light gradient */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for aesthetics */
  overflow: hidden!important; /* Prevent scrolling inside the dialog */
  z-index: 1000; /* Ensure the dialog is above other elements */
}
.company-success-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center everything horizontally */
  justify-content: flex-start; /* Align children at the top */
  height: 200px; /* Set the height to adjust the content area */
  position: relative; /* For absolute positioning of the button */
}
.company-success-message {
  font-size: 20px;
  font-weight: 500;
  color: #4caf50;
  top:-10px;
  text-align: center;
  margin-bottom:10px;
}
.company-ok-button {
  position: absolute;
  bottom: -10px; /* Distance from the bottom */
  right: 20px; /* Distance from the right */
}
.company-dialog-content {
  font-family: "Poppins", sans-serif; /* Font family */
  font-weight: 600; /* Bold font weight */
  font-size: 16px; /* Font size */
  display: flex; /* Flexbox for alignment */
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center horizontally */
  padding: 24px; /* Internal padding */
  justify-content: center; /* Center vertically */
  text-align: center; /* Center text */
  color: #333333; /* Text color */
  overflow: hidden !important;
}
.company-success-icon {
  width:80px;
  height: 80px;
  margin-bottom: 16px;
}
.company-ok-button {
  width: 80px;
  background: linear-gradient(90deg, #4caf50, #81c784);
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  border: none;
  border-radius: 2px;
  text-transform: capitalize;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
}
.company-ok-button:hover {
  background: linear-gradient(90deg, #81c784, #4caf50);
  color: white;
}
.company-reset-btn {
  font-family: "Manrope", sans-serif;
  color: #00619a;
  background: #fff;
  border: 1px solid #00619a;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 3px;
  padding: 10px 9px;
  width: auto;
  margin-top: -70px;
  margin-bottom: 20px;
  margin-left: 140px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
}
.company-reset-btn:hover {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #fff;
  border-color: #00619a;
}
.company-reset-btn:hover .reset-icon {
  color: #fff;
}

.enquiry-error-paper {
  max-width: 600px; /* Limit the maximum width */
  width: 50%; /* Percentage-based width for responsiveness */
  height: auto; /* Auto height based on content */
  margin: 20px auto 0; /* Center the dialog horizontally */
  padding: 0px; /* No extra padding around the dialog */
  background-color: #fff6f6; /* Light red background for error dialog */
  
  border-radius: 15px; /* Rounded corners for the outer box */
  border: 2px solid rgba(255, 0, 0, 0.6); /* Thin red border around the dialog */
  border-left: 10px solid rgba(255, 0, 0, 0.6); /* Thick left border with red color */
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better aesthetics */
  overflow: hidden; /* Prevent scrolling inside the dialog */
  
  position: fixed; /* Fix the dialog at the top of the page */
  top: 20px; /* Set the top distance for the dialog */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Corrects centering */
  z-index: 1000; /* Ensure the dialog is on top */
}
.product-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
 /* Aligns the error message at the top */
}
/* Styling for the content inside the dialog */
.product-error-content {
  position: relative; /* For positioning child elements */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: flex-start; /* Align content to the top */
  overflow: hidden; 
 
 
}
/* Styling for the error message container */
.product-errormessage-container {
  position: relative; /* Positioning relative to parent */
  
  background-color: #fff6f6; /* Light red background */
  padding: 20px 30px; /* Padding inside the container */
  display: flex;
  align-items: center; /* Vertically align content */
  z-index: 1; /* Ensure it stays on top */
  box-shadow: none; /* Remove inner shadow */
  border-radius: 10px; /* Rounded corners */
}
/* Styling for the error icon */
.error-icon {
  background-color: rgba(255, 0, 0, 0.6); /* Red background for the icon */
  color: #ffffff; /* White text color */
  border-radius: 50%; /* Circular icon */
  width: 100px; /* Icon width */
  height: 100px; /* Icon height */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold; /* Bold icon text */
  font-size: 60px; /* Larger font size for the icon */
  margin-right: 10px; /* Spacing between icon and text */
  margin-left: -40px; /* Adjust positioning for icon */
  margin-top: -20px; /* Fine-tune vertical alignment */
}
/* Styling for the text container inside the dialog */
.error-text-container {
  display: flex;
  flex-direction: column; /* Stack text vertically */
  justify-content: flex-start; /* Align text at the top */
  margin-top: -20px; /* Adjust vertical alignment */
}
/* Styling for the action text (primary message) */
.error-action {
  font-weight: bold; /* Bold text for emphasis */
  font-family: "Manrope", sans-serif; /* Clean font */
  color: #333333; /* Neutral text color */
  font-size: 22px; /* Larger font size */
  margin-bottom: 5px; /* Spacing between action text and message */
}
/* Styling for the error message text */
.error-message {
  color: #333333; /* Neutral text color */
  font-family: "Manrope", sans-serif; /* Clean font */
  font-size: 18px; /* Larger font size for readability */
  line-height: 1.5; /* Line height for better readability */
}
.spinner-container {
  display: flex;
  justify-content: center;   /* Centers horizontally */
  align-items: center;       /* Centers vertically */
  position: fixed;           /* Fixed position to stay in place */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);  /* Optional dimmed background */
  z-index: 9999;             /* Ensure it appears above other elements */
  flex-direction: column;    /* Stack text and spinner vertically */
  gap: 10px;                 /* Space between text and spinner */
}

.spinner-custom {
  width: 2.5rem;
  height: 2.5rem;
  border-width: 0.4rem;
  border-style: solid;
  border-color: #243a73 transparent transparent transparent;
  border-radius: 50%;
  animation: spinner-border 1s linear infinite;
}
@keyframes spinner-border {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 1rem;
  font-weight: bold;
  color: #243a73;
  text-align: center;
  padding: 10px;  /* Optional padding */
}

.pinerror-message {
  color: red;
  font-size: 12px;
  margin-top: 3px; /* Add small spacing */
  position: absolute;
  bottom: -14px; /* Adjust to keep input alignment */
  left: 610px;
}
.gsterror-message {
  color: red;
  font-size: 12px;
  margin-top: 3px; /* Add small spacing */
  position: absolute;
  bottom: -14px; /* Adjust to keep input alignment */
  left: 1080px;
}
.pinenqerror-message {
  color: red;
  font-size: 12px;
  margin-top: 3px; /* Add small spacing */
  position: absolute;
  bottom: -14px; /* Adjust to keep input alignment */
  left: 140px;
}
.gstenqerror-message {
  color: red;
  font-size: 12px;
  margin-top: 3px; /* Add small spacing */
  position: absolute;
  bottom: -14px; /* Adjust to keep input alignment */
  left: 600px;
}
.phoneerror-message {
  color: red;
  font-size: 12px;
  margin-top: 3px; /* Add small spacing */
  position: absolute;
  bottom: 265px; /* Adjust to keep input alignment */
  left: 400px;
}
.input-error {
  border: 1px solid red !important; /* Red border when invalid input */
   /* Optional: Light red background for better visibility */
  border-radius: 2px; /* Border radius to match the input */
}
