@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Nunito&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');
  
  .table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .quota-table {
    width: 100%;
    margin-top: -10px;
    border-collapse: collapse;
    background-color: #fff;
    font-family: 'Manrope';
    font-size: 15px;
    margin-top: 2px;
  }
  
  .quota-table th,
  .quota-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .add-quota-btn {
    padding: 7px 15px;
    font-size: 14px;
    background-color: #0B5394;
    font-family: 'Manrope';
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: auto;
  }
  
  .quota-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .quotax-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modelopen-container {
    background: #A5BECC;
    padding: 30px;
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
    position: relative;
  }
  .modeltax-container {
    background: #A5BECC;
    padding: 30px;
    border-radius: 5px;
    width: 80%;
    max-width: 300px;
    position: relative;
  }
  
  .quota-header {
    background-color: #243A73;
    color: #FFFFFF;
    font-size: 12px;
    height: 40px;
    margin-top: -31px;
    margin-bottom: 30px;
    text-align: right;
    position: relative;
    right: 30px;
    width: 114%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: right;
    justify-content: center;
  }
  .quotax-header {
    background-color: #243A73;
    color: #FFFFFF;
    font-size: 12px;
    height: 40px;
    margin-top: -31px;
    margin-bottom: 30px;
    text-align: right;
    position: relative;
    right: 30px;
    width: 300px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: right;
    justify-content: center;
  }
  .quota-titles {
    margin-top: 10px;
    text-align: left;
    color: #ffffff;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 500;
  }
  
  .modal-close {
    position: absolute;
    top: -5px;
    left: 470px;
    cursor: pointer;
    font-size: 28px;
    color: white;
  }
  .modal-tax {
    position: absolute;
    top: -5px;
    left: 270px;
    cursor: pointer;
    font-size: 28px;
    color: white;
  }
  .model-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .model-label {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    margin-right: 10px;  
    width: 140px;        
  }
  
  .input-model{
    width: 100%;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 34px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #b8b8b8;
  }
  
  .area-field {
    width: calc(100% - 20px);
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 60px;
    font-size: 14px;
  }
  
  .modal-footer {
    margin-top: 10px;
    text-align: right;
  }
  .SGST-primary {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    background: linear-gradient(to right, #0b5394, #00619a);
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    width: auto;
    margin-top: 0px;
    margin-bottom: 20px;
    color: white;
    margin-right: 120px;
    cursor: pointer;
    transition: transform 0.2s ease, background 0.3s ease;
  }
  
  .SGST-primary:hover {
    background: linear-gradient(to right, #00598d, #0b5394); 
    
  }
  
  .SGST-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .IGST-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .IGST-primary {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    background: linear-gradient(to right, #0b5394, #00619a);
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    width: auto;
    margin-top: -60px;
    margin-bottom: 20px;
    margin-left: 90px;
    cursor: pointer;
        transition: transform 0.2s ease, background 0.3s ease;
  }
     .IGST-primary:hover {
    background: linear-gradient(to right, #00598d, #0b5394); 
    
  }
  .enquiry-area {
    width: 100%; 
    max-width: 600px; 
    height: 140px; 
    padding: 12px; 
    font-size: 16px; 
    font-family: "Poppins", sans-serif; 
    color: #333; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
    box-sizing: border-box; 
    resize: none; }
