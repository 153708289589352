@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');
/* Ensure body and html take full height */
body, html {
  margin: 0;
  padding: 0;
  height: 100%; /* Full height */
  width: 100%; /* Full width */
}
.company-box {
  /* border: 1px solid #ccc; */
  width: 100%;
  height: auto;
  padding: 8px;
  border-radius: 5px;
  margin: 2px;
  margin-top: -70px;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); */
}

.company-contain{
  display: grid;
  justify-items: end; 
  margin-bottom: 15px;
}

.add-company-button {
  background: linear-gradient(to right, #0B5394, #00619A);
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 230px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-left: 1005px;
  margin-top: 10px;
}

.add-company-button:hover {
  background: linear-gradient(to right, #00619A, #0B5394);
}
.add-company-button-icon {
  margin-right: 18px;
  font-size: 18px;} 

  .spinner-container {
    display: flex;
    justify-content: center;   /* Centers horizontally */
    align-items: center;       /* Centers vertically */
    position: fixed;           /* Fixed position to stay in place */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);  /* Optional dimmed background */
    z-index: 9999;             /* Ensure it appears above other elements */
    flex-direction: column;    /* Stack text and spinner vertically */
    gap: 10px;                 /* Space between text and spinner */
  }
  
  .spinner-custom {
    width: 2rem;
    height: 2rem;
    border-width: 0.3rem; 
    border-color:#243a73 transparent transparent transparent;
    animation: spinner-border 1s linear infinite;
  }
  @keyframes spinner-border {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-text {
    font-size: 1rem;
    font-weight: bold;
    color: #243a73;
    text-align: center;
    padding: 10px;  /* Optional padding */
  }
  