@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');



  .quotation-box {
    /* border: 1px solid #ccc; */
    width: 100%;
    height: auto;
    padding: 8px;
    border-radius: 5px;
    margin: 2px;
    margin-top: 80px;
    /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); */
  }
  
.quotation-sn{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  text-align: center;
  width: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-Size: 12px;
}
.quotation-id{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 130px;
}
.quotation-name{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 160px;
}
.quotation-contact{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  width: 140px;
}
.quotation-mobile{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 150px;
  font-weight: 500;

}
.quotation-assigned{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 120px;
  font-weight: 500;

}
.quotation-version{
    background: linear-gradient(to right, #0B5394, #00619A);
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    width: 100px;
    font-weight: 500;
  
  }
.quotation-value{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 100px; 
  font-weight: 500;

}
.quotation-date{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  width: 100px; 
  font-weight: 500;

}
.quotation-status{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF !important;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 80px; 
  font-weight: 500;

}
.quotation-action{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 100px; 
  font-weight: 500;

}
.cell-quotation{
  font-family: 'Manrope', sans-serif;
  font-Size: "14px";
  text-align: center !important;
  }
.status-cell {
  text-align: center;
  padding: 5px;
  border-radius: 2px;
  background-color: #dff0d8; 
  color: #3c763d;
}

.act {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Delete Action Icon */
.action-delete {
  cursor: pointer;
  color: rgb(255, 103, 103);
  background-color: rgba(255, 103, 103, 0.1); 
  border-radius: 5px; 
  padding: 5px; 
  display: inline-flex; 
  justify-content: center; 
  align-items: center; 
  font-size: 24px; 
  transition: all 0.3s ease; 
}

.action-delete:hover {
  background-color: rgba(255, 103, 103, 0.2);  
  transform: scale(1.1);  
}

.action-edit {
  margin-left: 10px;
  cursor: pointer;
  color: rgb(103, 151, 255);
  background-color: rgba(103, 151, 255, 0.1); 
  border-radius: 5px;
  padding: 5px; 
  display: inline-flex; 
  justify-content: center; 
  align-items: center; 
  font-size: 24px; 
  transition: all 0.3s ease; 
}

.action-edit:hover {
  background-color: rgba(103, 151, 255, 0.2);  
  transform: scale(1.1);  }


.icon-tip{
  color: white;
  font-size: 15px;
}
.quotation-buttons {
  display: grid;
  justify-items: end; 
  margin-bottom: 30px;
}

.add-quota-button {
  margin-left: 900px;
  background: linear-gradient(to right, #0B5394, #00619A);
  margin-top: -140px;
  height: 40px;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  font-family: "Poppins", sans-serif;
}

.add-quota-icon {
  margin-right: 8px;
  font-size: 18px;

} 
