@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');
.Header {
  display: flex;
  justify-content: center; 
  align-items: center;    
  width: 100%;            
  padding: 25px;
  background-color: #f4f4f4;
  box-sizing: border-box; 
  white-space: nowrap; 
}

.Header-content {
  display: flex;
  flex: 1;                
  flex-wrap: wrap;        
  justify-content: center;
  align-items: center;
  max-width: 100%;     
}

.Header-title {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;       
  font-weight: bolder;
  color: black;
  text-align: start;
  margin: 0;
  flex: 1;                
}


