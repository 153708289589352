@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Nunito&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap");
/* Closure.css */
.closure-box {
  /* border: 1px solid #ccc; */
  width: 100%;
  height: auto;
  padding: 8px;
  border-radius: 5px;
  margin: 4px;
  margin-top: -20px ;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); */
}
.closure-container {
  padding: 20px;
}

.closure-btn {
  background: linear-gradient(to right, #0b5394, #00619a);
  border: none;
  color: white;
  padding: 10px 20px;
  margin-top: -1px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: -20px;
  font-family: "Poppins", sans-serif;
}

.custom-modal {
  width: 400px;
  margin-left: 50%;
  transform: translateX(-50%);
}

/* .modal-head {
  background-color: #243a73;
  height: 50px !important;
  width: 400px !important;
  margin-left: 0px !important;
  margin-bottom: 30px !important;

} */


/* .modal-title {
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-left: 120px;
  margin-bottom: 4px; */
/* } */
.modal-head {
  background-color: #243a73;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; 
 
}

.modal-title {
  flex: 1;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-left: 20px;
  margin-bottom: 4px; 
  margin-top: -5px;
  height:50px;
}
.close-button {
  position: absolute;
  right: 20px; /* Adjust the distance from the right */
  top: 25%; /* Vertically center the button */
  transform: translateY(-50%); /* Center it exactly in the middle */
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.close-button:hover {
  color: #7eb6f3; /* Optional: Change color on hover */
}
.modal-body {
  background-color: #a5becc;
  margin-top: -32px;
}

.pop-up {
  margin-bottom: 20px;
}

.pop-up-label {
  width: 120px;
  text-align: left;
  margin-bottom: 10px;
}

.pop-up-input {
  display: flex;
  justify-content: flex-start;
}

.pop-up-control {
  width: 80%;
  height: 34px;
  padding-left: 15px;
  padding-right: 30px;
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  margin-left: 80px;
  border: 1px solid #ddd;
  margin-top: -40px;
}
.pop-up-control-text {
  width: 80%;
  height: 80px;
  padding-left: 15px;
  padding-right: 30px;
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  margin-left: 80px;
  border: 1px solid #ddd;
  margin-top: -40px;
}
.character-count {
  position: absolute;
  right: 35px;
  bottom: 10px;
  font-size: 12px;
  color: #6c757d;
}

.modal-footer {
  background-color: #a5becc;
  margin-top: 0px !important;
}

.save-btn {
  background: linear-gradient(to right, #0b5394, #00619a);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.closure-sn {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 100px;
}
.closure-nextfollow {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 155px;
}
.closure-companyname {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 210px;
}
.closure-contact {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 110px;
}
.closure-order {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 135px;
}
.closure-source {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 100px;
}
.closure-assign {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 130px;
}
.closure-enqtitle{
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff !important;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 220px;

}
.closure-createddate{
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff !important;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 120px;

}
.closure-status{
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff !important;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 100px;

}
.closure-quotation{
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff !important;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 220px;

}
.closure-status {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff !important;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 80px;
}
.closure-note {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 90px;
}
.closure-view {
  background: linear-gradient(to right, #0b5394, #00619a);
  color: #ffffff;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 80px;
}
.cell-center {
  font-family: "Manrope", sans-serif;
  font-size: "14px";
  text-align: center !important;
}
.status-call {
  text-align: center;
  padding: 4px;
  border-radius: 2px;
  background-color: #dff0d8;
  color: #3c763d;
}
.icon-white {
  color: white;
  font-size: 15px;
}
.act-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.eye-container {
  display: flex; /* Enable Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Ensure it takes up the full width */
  height: 100%; /* Ensure it takes up the full height (adjust based on your layout) */
}


.eye-icon {
  font-size: 16px; /* Adjust the icon size */
  color: #007bff;  /* Icon color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: color 0.3s; /* Smooth color transition */
}
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: rgb(103, 151, 255); /* Set the text color */
}

.centered-container:hover {
  background-color: rgba(103, 151, 255, 0.2); /* Hover background color */
}


.act-icon {
  margin-left: 10px;
  cursor: pointer;
  color: rgb(103, 151, 255);
  background-color: rgba(103, 151, 255, 0.1);
  border-radius: 5px;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  transition: all 0.3s ease;
}
.act-icon:hover {
  background-color: rgba(103, 151, 255, 0.2);
  transform: scale(1.1);
}
.close-filter-button {
  background: linear-gradient(to right, #0b5394, #00619a);
  height: 40px;
  width: 100px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  margin-top: -80px;
  margin-left: 91%;
}
.filter-icon {
  margin-right: 8px;
  font-size: 25px;
  color: white;
}
.closure-filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.closure-filter-container {
  background: #a5becc;
  padding: 30px;
  border-radius: 5px;
  width: 40%;
  max-width: 500px;
  position: relative;
}
.closure-filter-header {
  background-color: #243a73;
  color: #ffffff;
  font-size: 12px;
  height: 40px;
  margin-top: -31px;
  margin-bottom: 30px;
  text-align: right;
  position: relative;
  right: 30px;
  width: 114%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: right;
  justify-content: center;
}
.closure-filter-titles {
  margin-top: 10px;
  text-align: left;
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
}
.closure-filter-close {
  position: absolute;
  top: -5px;
  left: 470px;
  cursor: pointer;
  font-size: 28px;
  color: white;
}
.closure-filter-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.closure-filter-label {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-right: 10px;
  width: 140px;
  font-weight: 500;
}

.date-range {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-input {
  width: 150px;
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.date-input::placeholder {
  color: #888;
  font-style: italic;
}

.status-checkbox {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: -20px;
}

.check-label {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #333;
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-radius: 4px;
  border: 2px solid #0b5394;
  background-color: #fff;
  cursor: pointer;
}
.modal-closure {
  display: flex;
  justify-content: center;
  align-items: center;
}

.closure-bth {
  padding: 10px 20px;
  background-color: #243a73;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
}
.spinner-container {
  display: flex;
  justify-content: center;   /* Centers horizontally */
  align-items: center;       /* Centers vertically */
  position: fixed;           /* Fixed position to stay in place */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);  /* Optional dimmed background */
  z-index: 9999;             /* Ensure it appears above other elements */
  flex-direction: column;    /* Stack text and spinner vertically */
  gap: 10px;                 /* Space between text and spinner */
}

.spinner-custom {
  width: 2rem;
  height: 2rem;
  border-width: 0.3rem; 
  border-color:#243a73 transparent transparent transparent;
  animation: spinner-border 1s linear infinite;
}


@keyframes spinner-border {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 1rem;
  font-weight: bold;
  color: #243a73;
  text-align: center;
  padding: 10px;  /* Optional padding */
}
.small-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; 
  color:blue;
}
.cell-ellipsis {
  display: inline-block;
  max-width: 200px;  /* Adjust width as needed */
  white-space: nowrap;
  overflow: hidden;
  color: black;
  text-overflow: ellipsis;
  cursor: pointer;
    /* Shows pointer to indicate tooltip */
}
