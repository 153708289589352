@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap');



  .Enquiry-box {
    /* border: 1px solid #ccc; */
    width: 100%;
    height: auto;
    padding: 8px;
    border-radius: 5px;
    margin: 2px;
    margin-top: -20px;
    /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); */
  }
  .enq-table{
    margin-top: -30px !important;
  }
  
.header-sn{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  text-align: center;
  width: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-Size: 12px;
}
.header-enqid{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 100px;
}
.header-quotation{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 200px;

}
.header-assigned{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  width: 100px;


}
.header-company{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;

  text-align: center;
  width: 220px;
}
.header-industry{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;

  text-align: center;
  width: auto;
}
.header-contact{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  width: auto;
}
.header-mobile{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: auto;
  font-weight: 500;

}
.header-job{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center!important;
  font-family: "Poppins", sans-serif;
  width: auto; 
  font-weight: 500;
 
 


}
.header-enqtitle{background: linear-gradient(to right, #0B5394, #00619A)!important;
  color: #FFFFFF!important;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;

  text-align: center;
  width: 210px;}


.header-email{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 220px;
  font-weight: 500;

}
.cell-ellipsis-enq {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; 
  line-clamp: 2; /* Standard property *//* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  max-width: 100%;
  max-height: 3rem; /* Approx height for 2 lines */
  line-height: 1.5rem;
  word-break: break-word;
  position: relative;
}





.header-value{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 100px; 
  font-weight: 500;

}
.header-createddate{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  width: 140px; 
  font-weight: 500;

}
.header-status{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF !important;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 80px;
  font-weight: 500;

}
.header-action{
  background: linear-gradient(to right, #0B5394, #00619A);
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 80px;
  font-weight: 500;

}
.cell-comellipsis{
  display: inline-block;
  max-width: 200px;  /* Adjust width as needed */
  white-space: nowrap;
  overflow: hidden;
  color: black;
  text-overflow: ellipsis;
  cursor: pointer;
    /* Shows pointer to indicate tooltip */
}
.cell-ellipsis {
  display: inline-block;
  max-width: 200px;  /* Adjust width as needed */
  white-space: nowrap;
  overflow: hidden;
  color: black;
  text-overflow: ellipsis;
  cursor: pointer;
    /* Shows pointer to indicate tooltip */
}



.cell-center-nowrap{
  font-family: 'Manrope', sans-serif;
  font-Size: "14px";
  text-align: center !important;
  }
.status-cell {
  text-align: center;
  padding: 5px;
  border-radius: 2px;
  background-color: #dff0d8; 
  color: #3c763d;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Delete Action Icon */
.action-icon-delete {
  cursor: pointer;
  color: rgb(255, 103, 103);
  background-color: rgba(255, 103, 103, 0.1); 
  border-radius: 5px; 
  padding: 5px; 
  display: inline-flex; 
  justify-content: center; 
  align-items: center; 
  font-size: 24px; 
  transition: all 0.3s ease; 
}

.action-icon-delete:hover {
  background-color: rgba(255, 103, 103, 0.2);  
  transform: scale(1.1);  
}

.action-icon-edit {
  margin-left: 10px;
  cursor: pointer;
  color: rgb(103, 151, 255);
  background-color: rgba(103, 151, 255, 0.1); 
  border-radius: 5px;
  padding: 5px; 
  display: inline-flex; 
  justify-content: center; 
  align-items: center; 
  font-size: 24px; 
  transition: all 0.3s ease; 
}

.action-icon-edit:hover {
  background-color: rgba(103, 151, 255, 0.2);  
  transform: scale(1.1);  }


.icon-color{
  color: white;
  font-size: 15px;
}

.but-contain {
  display: grid;
  justify-items: end; 
  margin-bottom: 30px;
}

.add-enquiry-button {
  margin-left: 900px;
  background: linear-gradient(to right, #0B5394, #00619A);
  margin-top: -70px;
  height: 40px;
  width: 230px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  border: none;
font-weight: 500;
border-radius: 3px;
}

.add-enquiry-button-icon {
  margin-right: 18px;
  font-size: 18px;} 
.filter-button{
  background: linear-gradient(to right, #0B5394, #00619A);
  height: 40px;
  width: 100px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  margin-top: -80px;
  margin-left: 73%;
  font-weight: 500;
  border-radius: 3px;
}
.filter-enquiry-button-icon{
  margin-right: 8px;
  font-size: 25px;
  color: white;
}
.filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.filter-container{
  background: #A5BECC;
    padding: 30px;
    border-radius: 5px;
    width: 40%;
    max-width: 500px;
    position: relative;
}
.filter-header{
  background-color: #243A73;
    color: #FFFFFF;
    font-size: 12px;
    height: 40px;
    margin-top: -31px;
    margin-bottom: 30px;
    text-align: right;
    position: relative;
    right: 30px;
    width: 114%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: right;
    justify-content: center;
}
.filter-titles{
  margin-top: 10px;
    text-align: left;
    color: #ffffff;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 500;
}
.filter-close{
  position: absolute;
    top: -5px;
    left: 470px;
    cursor: pointer;
    font-size: 28px;
    color: white;
}

.filter-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter-label {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-right: 10px;   
  width: 140px; 
  font-weight: 500;       
}

.input-filter{
  width: 100%;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 34px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #b8b8b8;
}
.date-range-container {
  display: flex;
  align-items: center;
  gap: 10px; }

.input-date {
  width: 150px; 
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.input-date::placeholder {
  color: #888; 
  font-style: italic;
}

.status-checkbox-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: -20px;
}

.checkbox-label {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #333;
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  width: 18px;  
  height: 18px; 
  margin-right: 8px;
  border-radius: 4px; 
  border: 2px solid #0B5394; 
  background-color: #fff; 
  cursor: pointer; 
}
.modal-foot {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-bth {
  padding: 10px 20px;
  background-color: #243A73;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}
.filter-bth:hover {
  background-color: #2f4c88; 
}
.filter-reset{
  padding: 10px 20px;
  background-color: #dfdfdf;
  color: #333; 
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}
.filter-reset:hover {
  background-color: #b0b0b0; 
}



.spinner-container {
  display: flex;
  justify-content: center;   /* Centers horizontally */
  align-items: center;       /* Centers vertically */
  position: fixed;           /* Fixed position to stay in place */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);  /* Optional dimmed background */
  z-index: 9999;             /* Ensure it appears above other elements */
  flex-direction: column;    /* Stack text and spinner vertically */
  gap: 10px;                 /* Space between text and spinner */
}

.spinner-custom {
  width: 2rem;
  height: 2rem;
  border-width: 0.3rem; 
  border-color:#243a73 transparent transparent transparent;
  animation: spinner-border 1s linear infinite;
}

@keyframes spinner-border {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 1rem;
  font-weight: bold;
  color: #243a73;
  text-align: center;
  padding: 10px;  /* Optional padding */
}
/* Modal container */
.custom-dialog {
  background: rgba(255, 255, 255, 0.9);
}

.custom-dialog-header {
  background-color: #4caf50; /* Success green */
  color: white;
}

.custom-dialog-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
text-align: center;
  color: #4caf50;
}

/* Modal body */
.custom-dialog-content {
  font-size: 18px;
  font-weight: 500;
  color: #4caf50;
  text-align: center;
  background-color: #fff;
  padding: 20px;
}

/* Modal footer */
.custom-dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 16px;
  background-color: #f9f9f9;
}

/* Button styles */
.custom-button-danger {
  width: 60px;
  background: linear-gradient(90deg, #4caf50, #81c784);
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  border: 2px solid #4caf50; /* Adds a visible border */
  border-radius: 8px; /* Increases border radius for rounded corners */
  text-transform: capitalize;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
  
}

.custom-button-secondary {
  background-color: #d9534f;
  width: 60px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  border: 2px solid #d9534f; /* Adds a visible border */
  border-radius: 8px; /* Increases border radius for rounded corners */
  text-transform: capitalize;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
}

.custom-button-secondary:hover {
  background: linear-gradient(90deg, #ff6b6b, #d9534f);

  color: white;
}

.custom-button-danger:hover {
  background: linear-gradient(90deg, #81c784, #4caf50);
  color: white;
}

/* Success message */
.success-message {
  font-size: 18px;
  font-weight: 500;
  color: #4caf50;
  text-align: center;
}

/* Dialog actions */
.enquiry-dialog-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 16px;
}
.followfilter-title {
  margin-top: 10px;
  text-align: left;
  color: blue;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.followfilter-close {
  position: absolute;
  top: 5px;
  left: 470px;
  cursor: pointer;
  font-size: 28px;
  color: white;
}

.modal-head {
  position: relative; /* Ensure the close button is positioned correctly */
}
