.sidebar-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../Images/Sidebarbackgroundimage.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 0;
}

.sidebar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.sidebar-contain {
  background: url('../Images/Sidebarbackgroundimage.png') no-repeat center center;
  background-size: stretch;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 260px; /* Full expanded width */
  height: 100vh;
  z-index: 2;
  color: white;
  overflow: hidden;
  transform: translateX(0); /* Default position (fully visible) */
  transition: transform 0.3s ease; /* Smooth sliding transition */
}

.sidebar-contain.hidden {
  transform: translateX(-100px); 
  width: 80px; /* Adjust the collapsed width *//* Slide left, expose only 60px of the sidebar */
}


.sidebar-content {
  position: relative;
  z-index: 2;
}

.sidebar-button {
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  margin: 10px;
  font-size: 20px;
  text-align: center;
  
}

/* sidebarmenu.css */

/* General menu styles */
.custom-menu {
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
  }
  
  
  /* Logo Section */
  .logo-section {
    text-align: center;
    color: white;
    margin-top: -50px;
  }
  
  .logo-img {
    width: 100px;
    height: 75px;
    margin: 0 auto;
  }
  
  /* Avatar and Username Section */
  .user-info {
    display: flex;
    align-items: center;
    padding: 15px;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    margin-bottom: 15px;
    border-radius: 15px;
    max-width: 90%;
    overflow: hidden;
  }
  
  .avatar-container {
    margin-right: 15px;
  }
  
  .avatar-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin-top: -10px;
  }
  
  .user-details {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .user-name {
    font-weight: 500;
    color: #f0f0f0;
    font-size: 13px;
    opacity: 0.8;
    white-space: nowrap;
  }
  
  .user-role {
    font-size: 12px;
    color: #f0f0f0;
    opacity: 0.8;
    white-space: nowrap;
  }
  
  .menu-item {
    display: flex;
    margin-top: -8px;
    color: white;
    justify-content: flex-start;
  }
  
  .menu-icon {
    font-size: 21px;
    color: white;
  }
  .menu-item.active {
    background-color: #0B5394;
    color: white;
    border-radius: 25px;
  }

 /* .sidebar-contain {
    height: 100vh; 
    text-align: left; 
    width: 260px;
    position: relative; 
    right: 200px;
    top: 0;
    left: 0;
    z-index: 1; 
    color: white;
    background-color: #333; 
    overflow: hidden;
    transition: width 0.3s ease; 
  }
  
  .sidebar-contain.collapsed {
    width: 80px; 
  }  */
  /* Dialog title styling */
  .logoutImage {
    width: 300px; /* Adjust the width of the image */
    height: 300px; /* Adjust the height of the image */
    object-fit: contain;
    justify-content: center;
    align-self: center; /* Keep image aspect ratio */
   margin-bottom: -30px;
   margin-top: -50px;
    margin-right: -10px; /* Add space below the image */
  }
.dialog-title {
  margin-top: -20px;
  font-size: 24px; /* Larger font size for the title */
  font-weight: bold; /* Make the title bold */
  text-align: center; /* Center the title */
  color:#333; /* Red color for the title */
  margin-bottom: 10px; /* Add space below the title */
  font-family: "Poppins", sans-serif; /* Set a clean font */
}

/* Dialog content styling */
.dialog-content {
  display: flex;
  flex-direction: column; /* Stack the paragraphs vertically */
  align-items: center; /* Center the text inside the dialog */
  padding: 20px; /* Add padding around the content */
  background-color: #f9f9f9; /* Light background color for the content */
  border-radius: 5px; /* Rounded corners */
}

/* Paragraph text styling */
.dialog-text {
  font-size: 18px; /* Set a readable font size for the text */
  color: #333; /* Dark text color for readability */
  text-align: center; /* Center the text */
  margin-bottom: 10px; /* Space between paragraphs */
  font-family: "Poppins", sans-serif; /* Set a clean font */
  line-height: 1.5; /* Improve readability */
}

.dialog-are {
  font-size: 18px; /* Set a readable font size for the text */
  color: #333; /* Dark text color for readability */
  text-align: center; /* Center the text */
  margin-bottom: -20px; /* Space between paragraphs */
  font-family: "Poppins", sans-serif; /* Set a clean font */
  line-height: 1.5; /* Improve readability */
}
.logout-dialog .MuiDialog-paper {
  padding: 20px; 
  border-radius: 30px; 
  background-color: #ffffff; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
 
    width: 25%; 
    max-width: none; 
    min-height: 200px; 
    height: 460px; 
    padding: 20px;
    font-family: "Poppins", sans-serif;  
}


.MuiDialog-paper {
 overflow: hidden;
 border-radius: 30px;

}





.button-container {
  display: flex;
  background-color: #007bff;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
  padding: 2px;
  margin-right: 30px; /* Optional padding around the container */
}
.dialog-actions {
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center buttons horizontally */
  align-items: center; /* Center buttons vertically */
  gap: 30px; /* Add space between Cancel and Logout buttons */
  width: 100%; /* Ensure the buttons span the full width */
  padding: 10px 0; /* Add vertical padding */
 /* Adjust vertical spacing */
}

/* Cancel Button Styling */
.cancelButton {
  background-color: #f5f5f5; /* Light grey background */
  color: #333; /* Dark text color */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 20px; /* Padding for better sizing */
  border: none; /* Remove default border */
  font-size: 16px; /* Font size for text */
  font-family: "Poppins", sans-serif; /* Font family */
  cursor: pointer; /* Pointer on hover */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.cancelButton:hover {
  background-color: #e0e0e0; /* Slightly darker grey on hover */
}

/* Logout Button Styling */
.logoutButton {
  background-color: #007bff; /* Blue background */
  color: white; /* Text color */
  font-size: 16px; /* Set a basic font size */
  padding: 10px 20px; /* Add some padding */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer on hover */
  display: inline-flex; /* Enable inline-flex for alignment */
  align-items: center; /* Align text and icon in the center */
  text-transform: uppercase; /* Capitalize the text */
  font-family: "Poppins", sans-serif; /* Font family */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.logoutButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Styling the logout text */
.logoutText {
  font-weight: bold; /* Make the text bold */
  margin-right: 5px; /* Space between the text and the right arrow */
  color: white;
}

/* Styling for the right arrow symbol */
.rightArrow {
  font-size: 18px; /* Size of the right arrow */
  margin-left: 5px; /* Space between the text and the right arrow */
  color: white; /* Color for the right arrow */
}
.spinner-container {
  display: flex;
  justify-content: center;   /* Centers horizontally */
  align-items: center;       /* Centers vertically */
  position: fixed;           /* Fixed position to stay in place */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);  /* Optional dimmed background */
  z-index: 9999;             /* Ensure it appears above other elements */
  flex-direction: column;    /* Stack text and spinner vertically */
  gap: 10px;                 /* Space between text and spinner */
}

.spinner-custom {
  width: 2rem;
  height: 2rem;
  border-width: 0.3rem; 
  border-color:#243a73 transparent transparent transparent;
  animation: spinner-border 1s linear infinite;
}

@keyframes spinner-border {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 1rem;
  font-weight: bold;
  color: #243a73;
  text-align: center;
  padding: 10px;  /* Optional padding */
}