@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&family=Lobster&family=Manrope&family=Nunito&family=Poppins:wght@200;400&family=Roboto:wght@400;500;700;900&family=Shadows+Into+Light&display=swap");

.basic-info-title {
  background-color: #243a73;
  color: white;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  margin-left: 10px;
  width: 100%;
  margin-top: 20px;

}

.basic-info-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 10px;
  background-color: #a5becc;
  padding: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
  width: 100%;
}

.basic-info-row {
  display: flex;
  align-items: center;
  
}

.basic-info-label {
  width: 200px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000;
  margin-bottom: 0;
}
.basic-info-search-icon {
  position: absolute;
  left: 370px;
  top: 14%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #888;
}
.required {
  color: red;
}

/* Input styles */
.basic-info-input {
  width: calc(100% - 20px);
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #6d6d6d;
  padding: 0 10px;
}

/* Dropdown list styles */
.basic-info-dropdown-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 50px;
  left: 90px;
  right: 0;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  width: 310px;
}
.basic-info-dropdown-item {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
}

.basic-info-dropdown-item:hover {
  background-color: #e6f7ff;
}

.row-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 30%;
  gap: 10px;
}
.product-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  background-color: #a5becc;
  padding: 18px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
  width: 100%;
 
}

.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-table {
  width: 100%;
  margin-top: 2px;
  border-collapse: collapse;
  background-color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  color: #333;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-table th {
  background-color: #0b5394;
  color: #fff;
  padding: 8px;
  width: 200px;
  text-align: left;
  text-transform: capitalize;
  letter-spacing: 0.05em;
}

.product-table td {
  padding: 8px;
  width: 200px;
  border-bottom: 1px solid #ddd;
}

/* Alternate row color */
.product-table tr:nth-child(even) {
  background-color: #f9fbfc;
}

/* Hover effect for rows */
.product-table tr:hover {
  background-color: #e1effa;
  transition: background-color 0.3s ease-in-out;
}

/* Remove last row border */
.product-table tr:last-child td {
  border-bottom: none;
}

.edit-icon {
  margin-left: 10px;
  cursor: pointer;
  color: rgb(103, 151, 255);
  background-color: rgba(103, 151, 255, 0.1);
  border-radius: 5px;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all 0.3s ease;
}
.edit-icon:hover {
  background-color: rgba(103, 151, 255, 0.2);
  transform: scale(1.1);
}
.trash-icon {
  margin-right: 10px;
  cursor: pointer;
  color: rgb(255, 103, 103);
  background-color: rgba(255, 103, 103, 0.1);
  border-radius: 5px;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all 0.3s ease;
}
.trash-icon :hover {
  background-color: rgba(255, 103, 103, 0.2);
  transform: scale(1.1);
}
.total-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.total-label {
  width: 150px;
  text-align: left;
  font-family: "Poppins";
  font-size: 12px;
  color: #000;
  margin-top: -7px;
}

.total-input {
  width: 100%;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: "Manrope";
  font-size: 14px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  margin-top: -4px;
  margin-left: -30px;
}

.add-product-button {
  padding: 7px 15px;
  font-size: 14px;
  background-color: #0b5394;
  font-family: "Poppins", sans-serif;
  border: none;
  font-weight: 500;
  border-radius: 3px;

  color: #fff;

  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
}
.right-align-button {
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  width: 100%;
}

.add-company-button {
  padding: 7px 15px;
  font-size: clamp(12px, 1.5vw, 14px); 
  background-color: #0b5394;
  font-family: "Poppins", sans-serif;
  border: none;
  font-weight: 500;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
    margin: 0;
}

.add-company-button:hover {
  background-color: #084073; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: #a5becc;
  padding: 30px;
  border-radius: 5px;
  width: 70%;
  max-width: 400px;
  position: relative;
}

.modal-header {
  background-color: #243a73;
  color: #ffffff;
  font-size: 12px;
  height: 40px;
  margin-top: -31px;
  margin-bottom: 30px;
  text-align: right;
  position: relative;
  right: 9%;
  width: 118%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: right;
  justify-content: center;
}

.modal-titles {
  margin-top: 10px;
  text-align: left;
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
}

.close-modal {
  position: absolute;
  top: -5px;
  left: 370px;
  cursor: pointer;
  font-size: 28px;
  color: white;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-label {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  margin-right: 10px; 
  width: 140px;
}

.input-field {
  width: 100%;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 34px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: black;
}

.product-input {
  width: calc(100% - 20px); /* Adjust width to fit */
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 34px;
  font-size: 14px;
  margin-left: 10px; /* Align with the label */
  cursor: pointer;
}

.textarea-field {
  width: 100%;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 60px;
  font-size: 14px;
}

.modal-footer {
  margin-top: 10px;
  text-align: right;
}

.submit-button {
  padding: 10px 20px;
  background-color: #243a73;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.dropdown-icon {
  position: absolute;
  right: 40px; /* Keeps the icon aligned to the right */
   font-size: 12px;
  cursor: pointer;
  color: #888;
}

.dropdown-list-1 {
  position: absolute;
  top: 100px; /* Adjust this based on your input's height */
  left: 134px;
  right: 0;
  max-height: 180px;
  overflow-y: auto;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  width: 237px;
}

.dropdown-item {
  padding: 8px 12px;
 
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
.enquiry-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  background-color: #a5becc;
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
  width: 100%;
}

.enquiry-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 30%;
  gap: 10px;
}

.enquiry-label {
  width: 150px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000;
}

.enquiry-input {
  width: calc(100% - 20px);
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #6d6d6d;
  padding: 0 10px;
}

.dropdown-list2 {
  position: absolute;
  top: 20px;
  width: 280px;
  max-height: 150px;
  left: 520px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1000;
  border-radius: 2px;
}

.dropdown-item2 {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.datepicker-container {
  display: flex;
  flex: 1;
}

.custom-datepicker {
  width: 120%;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #6d6d6d;
  padding: 0 10px;
}.product-primary {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    background: linear-gradient(to right, #0b5394, #00619a);
    border: 1px solid #00619a;
    border-radius: 3px;
    padding: 10px 10px;
    width: auto;
    margin-top: 0px;
    margin-bottom: 20px;
    color: white;
    cursor: pointer;
    margin-right: 100px;
  }
  .product-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  
  
  
  .product-dialog-paper {
    position: absolute; /* Keep fixed positioning */
    top: 20%; /* Center vertically */
    left: 20%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust to perfectly center */
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    background: linear-gradient(to bottom, #ffffff, #f8f8ff);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    max-width: 500px; /* Limit max width */
    width: 100%; /* Full width up to max-width */
    padding: 20px;
    box-sizing: border-box; /* Prevent overflow */
    overflow: hidden; /* Prevent scrolling */
    min-height: 200px; /* Ensure dialog has a minimum height */
  }
  
  .productsuccess-message {
    font-size: 18px;
    font-weight: 500;
    color: #4caf50;
    text-align: center;
    word-break: break-word;
    margin-top: 10px; /* Space between icon and message */
  }
  
  .product-success-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .product-dialog-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 15px; /* Space for actions */
  }
  
  
 
  
  .product-ok-button {
    width: 80px;
    background: linear-gradient(90deg, #4caf50, #81c784);
    font-weight: 500;
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    border: none;
    border-radius: 2px;
    text-transform: capitalize;
    color: white;
    transition: all 0.3s ease;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, background 0.3s ease;
  }
  .product-ok-button:hover {
    background: linear-gradient(90deg, #81c784, #4caf50);
    color: white;
  }
  .productreset-but {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .product-area {
    width: calc(100% - 20px);
    height: 140px;
    padding: 12px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; 
    resize: none;
  }
  .product-icon {
    margin-right: 8px;
    font-size: 18px;
  }
  .productsave-icon {
    margin-right: 6px;
    font-size: 25px;
  }
  .productreset-icon {
    margin-right: 6px;
    font-size: 25px;
    color: #00619a;
  }
  .product-reset-btn {
    font-family: "Manrope", sans-serif;
    color: #00619a;
    background: #fff;
    border: 1px solid #00619a;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 3px;
    padding: 10px 9px;
    width: auto;
    margin-top: -2px;
    margin-bottom: 20px;
    margin-left: -110px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 7px;
  }
  
  .product-reset-btn:hover {
    background: linear-gradient(to right, #0b5394, #00619a);
    color: #fff;
    border-color: #00619a;
  }
  
  .product-reset-btn:hover .reset-icon {
    color: #fff;
  }
  .button-container {
    position: relative;
   height:50px;
   top: -75px;
   margin-bottom:-55px;
   margin-right: -36px;
   
    
  }
  
  .button-backArrow {
    position: absolute; /* Position the button relative to the container */
    top: 20px; /* Adjust the top position */
    right: 30px; /* Move the button to the right */
    padding: 10px;
   
    background: linear-gradient(to right, #0B5394, #00619A);
   
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    width: 120px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    
  }
  .circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px; /* Circle width */
    height: 20px; /* Circle height */
    background-color: white;/* Circle background color */
    border-radius: 50%; /* Make the div round */
    margin-right: 8px;
    margin-left: 10px; /* Space between the circle and the text */
  }
  .back-text {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    font-family: "Manrope", sans-serif;
    margin-left: 50px; /* Add space between the icon and text */
  
    color:white; 
    margin-top: -23px;/* Customize color as needed */
  }
  .herror-message {
    color: red;
    font-size: 12px;
    margin-top: 3px; /* Add small spacing */
    position: absolute;
    bottom: 595px; /* Adjust to keep input alignment */
    left: 1190px;
  }
  .uerror-message {
    color: red;
    font-size: 12px;
    margin-top: 3px; /* Add small spacing */
    position: absolute;
    bottom: 545px; /* Adjust to keep input alignment */
    left: 410px;
  }
  .merror-message {
    color: red;
    font-size: 12px;
    margin-top: 3px; /* Add small spacing */
    position: absolute;
    bottom: 545px; /* Adjust to keep input alignment */
    left: 1200px;
  }
  .input-error {
    border: 1px solid red !important; /* Red border when invalid input */
     /* Optional: Light red background for better visibility */
    border-radius: 2px; /* Border radius to match the input */
  }
  